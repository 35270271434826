<template>
  <page-main
    title="Let's cover your professional development:"
    nextText="Focus on a specific company"
    buttonTabIndex="6"
    eventName="nav"
    @nav="nav('/TargetCompany/')"
    @save="save()"
  >
    <template v-slot:aside>
      <p>What industries are you interested in?</p>
      <p>How do your skills line up with those required by those industries?</p>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="What industries are you looking to apply to, or are interested in?"
            tabindex="1"
            autofocus
            :optionsList="baseTargetIndustries"
            :selected.sync="industries"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-single-select
            v-model="targetIndustry"
            :items="industries"
            label="Of these, what is your targeted industry?"
            tabindex="2"
          >
          </r-single-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="What are some of your skills required for this industry?"
            :optionsList="baseSkills"
            :selected.sync="industrySkills"
            tabindex="3"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Other skills required by the industry that are not listed?"
            :selected.sync="otherSkills"
            tabindex="4"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="industryReasons"
            label="What about the target industry is interesting or attractive to you?"
            tabindex="5"
          ></r-textarea>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
  name: "TargetIndustry",
  data: () => ({}),
  components: {},
  mounted() {},
  methods: {
    save() {
      this.saveTargetIndustries();
    },
    ...mapActions("targetIndustry", ["saveTargetIndustries"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  computed: {
    ...mapFields("targetIndustry", [
      "industries",
      "targetIndustry",
      "industrySkills",
      "otherSkills",
      "industryReasons",
      "baseTargetIndustries",
    ]),
    ...mapFields("hardSkill", ["baseHardSkills"]),
    ...mapFields("softSkill", ["baseSoftSkills"]),
    baseSkills: function () {
      return [...this.baseHardSkills, ...this.baseSoftSkills];
    },
  },
};
</script>
